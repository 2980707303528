<template>
<div id="Awards">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<div>
  <div class="pa-5 secondary elevation-5 bg-accent-card">
    <p class="mt-5 display-1">Add Awards
    </p>
  </div>
</div>  

<v-container fluid style="margin-top: -200px">

    <v-form @submit.prevent="AddAward()">
    <v-card class="elevation-5 mb-5">
        <v-card-title>Add New Award</v-card-title>
        <v-card-text>
            <v-row>
            <!-- add form -->
            <v-col cols="12" sm="12" md="12">
                <v-text-field label="Title" :error="$v.Title.$error" :error-messages="TitleErrors"
                v-model.trim="Title" @input="$v.Title.$touch()" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
                <v-textarea label="Description"  :error="$v.Description.$error" :error-messages="DescriptionErrors"
                @input="$v.Description.$touch()" v-model="Description" required></v-textarea>
            </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="pa-3">
            <v-btn type="submit" class="success elevation-10" :disabled="disbtn">Add Award</v-btn>
            <v-btn @click="$router.go(-1)" class="secondary elevation-10">back</v-btn>
        </v-card-actions>

        <!-- show overlay when add item -->
        <v-fade-transition>
        <v-overlay absolute :value="disbtn">
          <v-progress-circular color="yellow" indeterminate width="15" size="100"></v-progress-circular>
        </v-overlay>
        </v-fade-transition>
        
    </v-card><br>
    </v-form>

</v-container>

</div>
</template>

<script>
// imports
import { required } from 'vuelidate/lib/validators';
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";

// scripts
export default {

    name: 'AddAward',
    
    data(){return{

        // app flags
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',disbtn: false,
        // award data
        Title: '',Description: ''
    }},

    methods:{

      // add post
      async AddAward(){

        // vars
        const dateObj = new Date();

        // blank validation
        if(!this.BlankValidation()){return false;}this.disbtn = true;

        // create a unique slug
        let awardslug = this.Slugify(this.Title) + dateObj.getMilliseconds() + '-kalalaya-academy-' +
                        Math.floor(Math.random() * 107477);  + dateObj.getSeconds().toString();

       
        // actual add statement
        // Add a new document with a generated id.
        firebase.firestore().collection("awards").add({
            "awardslug": awardslug, "title": this.Title,"description": this.Description
        }).then((doc) => {
                
            this.ResetFields(); this.notificationService("Successfully Added!", "success");
            this.disbtn = false;this.$store.dispatch('getAwards');

        }).catch((err) => {console.log(err)});

      },

      // reset fields
      ResetFields(){     
        ;this.Title = '';this.Description = '';
        this.$v.$reset();
      },

      // check if all fileds are empty   
      BlankValidation(){
        if(this.Title == '' || this.Description == '')
        {this.notificationService("Fill All Fields!", "red darken-1"); return false;}else{return true;}
      },
      
      // error pusher for vuelidate   
      ErrorPusher(basic, extra, msg){
        const errors = []
        if (!basic) return errors
        !extra && errors.push(msg);
        return errors;
      },

      // notification service
      notificationService(msg, type){
        this.notificationType = type;
        this.notificationMsg = msg;
        this.notificationToggle = true;
      },

      // slugify title (copied from medium :-D)
      Slugify(string) {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return string.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w\-]+/g, '') // Remove all non-word characters
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
      },

    },

     // validations
    validations: {
        Title: { required }, 
        Description: { required },
    },

    computed: {
  
      // basically in computed, i added validations  
      // sorry, i cant find a way to make this even smaller : -D
      TitleErrors () { return this.ErrorPusher(this.$v.Title.$dirty, this.$v.Title.required, 'Title is Required'); },
      DescriptionErrors () { return this.ErrorPusher(this.$v.Description.$dirty, this.$v.Description.required, 'Description is Required'); },
    }


}
</script>
